import React from 'react';
import { ISVG } from '../../../interfaces';

export const SVG = ({
    viewBox = '0 0 134 107',
    width = '67px',
    height = '53px',
}: ISVG): JSX.Element => {
    return (
        <svg width={width} height={height} viewBox={viewBox} version="1.1">
            <defs>
                <linearGradient
                    x1="22.3734841%"
                    y1="31.7179407%"
                    x2="91.5899021%"
                    y2="54.8510073%"
                    id="linearGradient-1"
                >
                    <stop stopColor="#FFBD77" offset="0%"></stop>
                    <stop stopColor="#F0F4A4" offset="23.9921585%"></stop>
                    <stop stopColor="#9CCCCC" offset="100%"></stop>
                </linearGradient>
            </defs>

            <g
                id="About"
                transform="translate(-683.000000, -501.000000)"
                fill="url(#linearGradient-1)"
                fillRule="nonzero"
            >
                <g
                    id="code-solid"
                    transform="translate(683.000000, 501.000000)"
                >
                    <path
                        d="M58.3953597,106.895779 L45.6244825,103.196477 C44.2845871,102.820277 43.530896,101.419977 43.9077416,100.082376 L72.4851965,1.81053139 C72.862042,0.472930785 74.2647449,-0.279469556 75.6046403,0.0967306147 L88.3755175,3.79603229 C89.7154129,4.17223246 90.469104,5.5725331 90.0922584,6.91013371 L61.5148035,105.181978 C61.1170221,106.519579 59.7352551,107.292879 58.3953597,106.895779 L58.3953597,106.895779 Z M34.5284743,83.4459684 L43.6355753,73.748364 C44.5986251,72.7242636 44.5358175,71.0940628 43.4680884,70.1535624 L24.5001953,53.4962548 L43.4680884,36.8389473 C44.5358175,35.8984469 44.619561,34.2682461 43.6355753,33.2441457 L34.5284743,23.5465413 C33.5863604,22.5433408 31.9952347,22.4806408 30.9693774,23.4420412 L0.800796813,51.657054 C-0.266932271,52.6393545 -0.266932271,54.3322552 0.800796813,55.3145557 L30.9693774,83.5504685 C31.9952347,84.5118689 33.5863604,84.4700689 34.5284743,83.4459684 L34.5284743,83.4459684 Z M103.030623,83.5713685 L133.199203,55.3354557 C134.266932,54.3531552 134.266932,52.6602545 133.199203,51.677954 L103.030623,23.4211412 C102.025701,22.4806408 100.434575,22.5224408 99.4715257,23.5256412 L90.3644247,33.2232456 C89.4013749,34.2473461 89.4641825,35.8775468 90.5319116,36.8180473 L109.499805,53.4962548 L90.5319116,70.1535624 C89.4641825,71.0940628 89.380439,72.7242636 90.3644247,73.748364 L99.4715257,83.4459684 C100.41364,84.4700689 102.004765,84.5118689 103.030623,83.5713685 Z"
                        id="Shape"
                    ></path>
                </g>
            </g>
        </svg>
    );
};
